// Core
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

// Utils
import { showError } from 'validators/showError';

// UI
import { ArtistsAutosuggestInput } from 'components/Inputs';

// Icons
import plus_bold from 'images/plus_bold.svg';
import close_bold from 'images/close_bold.svg';

// Styles
import s from './PerformerItem.module.css';

const PerformerItem = ({
	performer,
	index,
	lastIndex,
	personal,
	handleChangeArtist,
	errors,
	cleanErrorsField,
	handleOpenModal,
	handleAddArtist,
	handleDeleteArtist,
	styles,
	label,
	dataField,
	values,
	hideCrossIcon,
	isH11Edit,
	tracksForm,
}) => {
	const performerItemRef = useRef(null);
	const buttonRef = useRef(null);

	const getPlaceholder = () => {
		if (label) {
			return label;
		} else {
			if (index === 0) {
				return tracksForm
					? 'rod.release.create.step.release.label.artists.performer.tracks.required'
					: 'rod.release.create.step.release.label.artists.performer.required';
			} else {
				return tracksForm
					? 'rod.release.create.step.release.label.artists.performer.tracks'
					: 'rod.release.create.step.release.label.artists.performer';
			}
		}
	};

	// Detect Safari and add 'safari' class
	useEffect(() => {
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if (isSafari && buttonRef.current) {
			if (buttonRef.current) {
				buttonRef.current.classList.add(s.autosuggestButtonForSafari);
			}

			if (performerItemRef) {
				performerItemRef.current.classList.add(s.performerItemForSafari);
			}
		}
	}, []);

	return (
		<div className={s.performerItem} key={index} ref={performerItemRef}>
			<div className={s.autosuggestItem} style={styles ? styles : {}}>
				<FormattedMessage id={getPlaceholder()} values={values}>
					{(placeholder) => (
						<ArtistsAutosuggestInput
							placeholder={placeholder}
							value={performer}
							performers={personal[dataField]}
							onChange={(data) => handleChangeArtist(data, index, dataField)}
							errors={errors}
							cleanErrorsField={cleanErrorsField}
							lastIndex={lastIndex}
							openModal={(name, lastIndex) =>
								handleOpenModal(
									lastIndex ? '' : performer,
									index,
									name,
									dataField
								)
							}
							dataField={dataField}
							isH11Edit={isH11Edit}
						/>
					)}
				</FormattedMessage>
			</div>
			{!hideCrossIcon && (
				<button
					ref={buttonRef}
					className={s.autosuggestButton}
					onClick={
						performer === '' && index === personal?.[dataField]?.length - 1
							? () => handleAddArtist(dataField)
							: () => handleDeleteArtist(index, dataField)
					}
				>
					<img
						src={
							performer === '' && index === personal?.[dataField]?.length - 1
								? plus_bold
								: close_bold
						}
						alt=""
					/>
				</button>
			)}
			{errors &&
				Object.keys(errors).includes(dataField) &&
				errors[dataField][0] !== 'does not match' && (
					<span
						className={s.helper}
					>
						{errors[dataField] && errors[dataField] === 'обязательное поле' ? (
							<FormattedMessage id={`rod.release_create.error.required`} />
						) : (
							showError(dataField)(errors[dataField][0])
						)}
					</span>
				)}
		</div>
	);
};

export default PerformerItem;
