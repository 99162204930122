// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

// UI
import HeaderButton from './HeaderButton/HeaderButton';
import {
	StatisticByDatesSection,
	StatisticReports,
	StatisticByTerritoriesSectionCombined,
} from './StatisticCategory';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';

// Styles
import styles from './Statistic.module.css';
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';
import { RootContext } from 'contexts/RootContext';

const Statistic = (props) => {
	const { accountId } = useContext(AuthContext);
	const {
		showTitle,
		hideTitle,
		selectStatistic,
		setSelectedStatistic,
	} = useContext(UIContext);
	const { getMvpUserData, getAccountStatisticCheck } = useContext(RootContext);

	const [accountName, setAccountName] = useState('');
	const id = localStorage.getItem('accountId');
	const [route, setRoute] = useState('');

	const match = useRouteMatch();
	const history = useHistory();

	const isAdmin = props.isAdmin || false;

	useEffect(() => {
		if (selectStatistic) {
			setSelectedStatistic(selectStatistic.toLowerCase());
			if (isAdmin) {
				history.push(
					`/admin/v2/accounts/${
						props.accountId
					}/statistic/${selectStatistic.toLowerCase()}`
				);
			} else {
				history.push(
					`/accounts/${accountId}/statistic/${selectStatistic.toLowerCase()}`
				);
			}
		} else if (match.path.includes('bydate')) {
			setSelectedStatistic('bydate');
		} else if (match.path.includes('byterritory')) {
			setSelectedStatistic('byterritory');
		} else if (match.path.includes('statistic-reports')) {
			setSelectedStatistic('statistic-reports');
		} else {
			setSelectedStatistic('bydate');
		}

		if (!accountName && isAdmin) {
			getMvpUserData(props.accountId).then((res) => {
				setAccountName(res.personal_data.title);
			});
		}
		if (!isAdmin) showTitle('rod.navbar.statistic');
		return function cleanup() {
			if (!isAdmin) hideTitle();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setRouteData = async () => {
		try {
			const { data: res } = await getAccountStatisticCheck(id);
			const { statistic_types } = res;
			if (statistic_types.length === 1) {
				if (statistic_types.includes('copyright')) {
					setRoute('/compositions');
				} else if (statistic_types.includes('allied')) {
					setRoute('/phonograms');
				}
			}
		} catch (error) {
			console.error('Error fetching account statistics:', error);
		}
	};

	useEffect(() => {
		const fetchRouteData = async () => {
			try {
				await setRouteData();
			} catch (error) {
				console.error('Error fetching account statistics:', error);
			}
		};
		if (id) {
			fetchRouteData();
			return () => {
				setSelectedStatistic('bydate');
			};
		}
	}, [id]);

	const getLink = (name) => {
		switch (name) {
			case 'bydate':
				setSelectedStatistic('bydate');
				return history.push(
					isAdmin
						? `/admin/v2/accounts/${props.accountId}/statistic/bydate${route}`
						: `/accounts/${accountId}/statistic/bydate${route}`
				);
			case 'byterritory':
				setSelectedStatistic('byterritory');
				return history.push(
					isAdmin
						? `/admin/v2/accounts/${props.accountId}/statistic/byterritory${route}`
						: `/accounts/${accountId}/statistic/byterritory${route}`
				);
			case 'statistic-reports':
				setSelectedStatistic('statistic-reports');
				return history.push(
					isAdmin
						? `/admin/v2/accounts/${props.accountId}/statistic/statistic-reports`
						: `/accounts/${accountId}/statistic/statistic-reports`
				);
			default:
				history.push(
					isAdmin
						? `/admin/v2/accounts/${props.accountId}/statistic/bydate${route}`
						: `/accounts/${accountId}/statistic/bydate${route}`
				);
				break;
		}
	};

	return (
		selectStatistic && (
			<TabsUnstyled defaultValue={selectStatistic} className={styles.statistic}>
				<TabsListUnstyled className={styles.statistic__header}>
					<HeaderButton
						className={styles.statistic__headerButton}
						value={'bydate'}
						onClick={() => getLink('bydate')}
					>
						<FormattedHTMLMessage id={'rod.statistic.by_date'} />
					</HeaderButton>
					<HeaderButton
						className={styles.statistic__headerButton}
						value={'byterritory'}
						onClick={() => getLink('byterritory')}
					>
						<FormattedHTMLMessage id={'rod.statistic.by_territory'} />
					</HeaderButton>
					<HeaderButton
						className={styles.statistic__headerButton}
						value={'statistic-reports'}
						onClick={() => getLink('statistic-reports')}
					>
						<FormattedHTMLMessage id={'rod.navbar.statistic_reports'} />
					</HeaderButton>
				</TabsListUnstyled>
				<div className={styles.statistic__main}>
					<TabPanelUnstyled value={'bydate'}>
						{isAdmin ? (
							<StatisticByDatesSection adminAccountId={props.accountId} />
						) : (
							<StatisticByDatesSection />
						)}
					</TabPanelUnstyled>
					<TabPanelUnstyled value={'byterritory'}>
						<StatisticByTerritoriesSectionCombined
							adminAccountId={props.accountId || undefined}
						/>
					</TabPanelUnstyled>
					<TabPanelUnstyled value={'statistic-reports'}>
						{isAdmin ? (
							<StatisticReports
								adminAccountId={props.accountId}
								accountName={accountName}
							/>
						) : (
							<StatisticReports />
						)}
					</TabPanelUnstyled>
				</div>
			</TabsUnstyled>
		)
	);
};

export default compose(withUI, withAuth, withRoot)(Statistic);
