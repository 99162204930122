export const timeZones = [
	{
		id: '-12:00',
		gmt: 'GMT-12:00',
		title_en: 'GMT-12:00, Eniwetok, Kwajalein',
		title_ru: 'GMT-12:00, Эниветок, Кваджалейн',
	},
	{
		id: '-11:00',
		gmt: 'GMT-11:00',
		title_en: 'GMT-11:00, Midway Island, Samoa',
		title_ru: 'GMT-11:00, Мидуэй, Самоа',
	},
	{
		id: '-10:00',
		gmt: 'GMT-10:00',
		title_en: 'GMT-10:00, Hawaii',
		title_ru: 'GMT-10:00, Гавайи',
	},
	{
		id: '-09:30',
		gmt: 'GMT-9:30',
		title_en: 'GMT-9:30, Taiohae',
		title_ru: 'GMT-9:30, Тайохаэ',
	},
	{
		id: '-09:00',
		gmt: 'GMT-9:00',
		title_en: 'GMT-9:00, Alaska',
		title_ru: 'GMT-9:00, Аляска',
	},
	{
		id: '-08:00',
		gmt: 'GMT-8:00',
		title_en: 'GMT-8:00, Pacific Time (US & Canada)',
		title_ru: 'GMT-8:00, Тихоокеанское время (США и Канада)',
	},
	{
		id: '-07:00',
		gmt: 'GMT-7:00',
		title_en: 'GMT-7:00, Mountain Time (US & Canada)',
		title_ru: 'GMT-7:00, Горное время (США и Канада)',
	},
	{
		id: '-06:00',
		gmt: 'GMT-6:00',
		title_en: 'GMT-6:00, Central Time (US & Canada), Mexico City',
		title_ru: 'GMT-6:00, Центральное время (США и Канада), Мехико',
	},
	{
		id: '-05:00',
		gmt: 'GMT-5:00',
		title_en: 'GMT-5:00, Eastern Time (US & Canada), Bogota, Lima',
		title_ru: 'GMT-5:00, Восточное время (США и Канада), Богота, Лима',
	},
	{
		id: '-04:30',
		gmt: 'GMT-4:30',
		title_en: 'GMT-4:30, Caracas',
		title_ru: 'GMT-4:30, Каракас',
	},
	{
		id: '-04:00',
		gmt: 'GMT-4:00',
		title_en: 'GMT-4:00, Atlantic Time (Canada), Caracas, La Paz',
		title_ru: 'GMT-4:00, Атлантическое время (Канада), Каракас, Ла-Пас',
	},
	{
		id: '-03:30',
		gmt: 'GMT-3:30',
		title_en: 'GMT-3:30, Newfoundland',
		title_ru: 'GMT-3:30, Ньюфаундленд',
	},
	{
		id: '-03:00',
		gmt: 'GMT-3:00',
		title_en: 'GMT-3:00, Brazil, Buenos Aires, Georgetown',
		title_ru: 'GMT-3:00, Бразилия, Буэнос-Айрес, Джорджтаун',
	},
	{
		id: '-02:00',
		gmt: 'GMT-2:00',
		title_en: 'GMT-2:00, Mid-Atlantic',
		title_ru: 'GMT-2:00, Срединно-Атлантический',
	},
	{
		id: '-01:00',
		gmt: 'GMT-1:00',
		title_en: 'GMT-1:00, Azores, Cape Verde Islands',
		title_ru: 'GMT-1:00, Азоры, Острова Зелёного Мыса',
	},
	{
		id: '+00:00',
		gmt: 'GMT',
		title_en: 'GMT+0:00, Western Europe Time, London, Lisbon, Casablanca',
		title_ru: 'GMT+0:00, Западноевропейское время, Лондон, Лиссабон, Касабланка',
	},
	{
		id: '+01:00',
		gmt: 'GMT+1:00',
		title_en: 'GMT+1:00, Brussels, Copenhagen, Madrid, Paris',
		title_ru: 'GMT+1:00, Брюссель, Копенгаген, Мадрид, Париж',
	},
	{
		id: '+02:00',
		gmt: 'GMT+2:00',
		title_en: 'GMT+2:00, Kaliningrad, South Africa',
		title_ru: 'GMT+2:00, Калининград, Южная Африка',
	},
	{
		id: '+03:00',
		gmt: 'GMT+3:00',
		title_en: 'GMT+3:00, Baghdad, Riyadh, Moscow, St. Petersburg',
		title_ru: 'GMT+3:00, Багдад, Эр-Рияд, Москва, Санкт-Петербург',
	},
	{
		id: '+03:30',
		gmt: 'GMT+3:30',
		title_en: 'GMT+3:30, Tehran',
		title_ru: 'GMT+3:30, Тегеран',
	},
	{
		id: '+04:00',
		gmt: 'GMT+4:00',
		title_en: 'GMT+4:00, Abu Dhabi, Muscat, Baku, Tbilisi',
		title_ru: 'GMT+4:00, Абу-Даби, Маскат, Баку, Тбилиси',
	},
	{
		id: '+04:30',
		gmt: 'GMT+4:30',
		title_en: 'GMT+4:30, Kabul',
		title_ru: 'GMT+4:30, Кабул',
	},
	{
		id: '+05:00',
		gmt: 'GMT+5:00',
		title_en: 'GMT+5:00, Ekaterinburg, Islamabad, Karachi, Tashkent',
		title_ru: 'GMT+5:00, Екатеринбург, Исламабад, Карачи, Ташкент',
	},
	{
		id: '+05:30',
		gmt: 'GMT+5:30',
		title_en: 'GMT+5:30, Bombay, Calcutta, Madras, New Delhi',
		title_ru: 'GMT+5:30, Бомбей, Калькутта, Мадрас, Нью-Дели',
	},
	{
		id: '+05:45',
		gmt: 'GMT+5:45',
		title_en: 'GMT+5:45, Kathmandu, Pokhara',
		title_ru: 'GMT+5:45, Катманду, Покхара',
	},
	{
		id: '+06:00',
		gmt: 'GMT+6:00',
		title_en: 'GMT+6:00, Almaty, Dhaka, Colombo',
		title_ru: 'GMT+6:00, Алматы, Дакка, Коломбо',
	},
	{
		id: '+06:30',
		gmt: 'GMT+6:30',
		title_en: 'GMT+6:30, Yangon, Mandalay',
		title_ru: 'GMT+6:30, Янгон, Мандалай',
	},
	{
		id: '+07:00',
		gmt: 'GMT+7:00',
		title_en: 'GMT+7:00, Bangkok, Hanoi, Jakarta',
		title_ru: 'GMT+7:00, Бангкок, Ханой, Джакарта',
	},
	{
		id: '+08:00',
		gmt: 'GMT+8:00',
		title_en: 'GMT+8:00, Beijing, Perth, Singapore, Hong Kong',
		title_ru: 'GMT+8:00, Пекин, Перт, Сингапур, Гонконг',
	},
	{
		id: '+08:45',
		gmt: 'GMT+8:45',
		title_en: 'GMT+8:45, Eucla',
		title_ru: 'GMT+8:45, Юкла',
	},
	{
		id: '+09:00',
		gmt: 'GMT+9:00',
		title_en: 'GMT+9:00, Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
		title_ru: 'GMT+9:00, Токио, Сеул, Осака, Саппоро, Якутск',
	},
	{
		id: '+09:30',
		gmt: 'GMT+9:30',
		title_en: 'GMT+9:30, Adelaide, Darwin',
		title_ru: 'GMT+9:30, Аделаида, Дарвин',
	},
	{
		id: '+10:00',
		gmt: 'GMT+10:00',
		title_en: 'GMT+10:00, Eastern Australia, Guam, Vladivostok',
		title_ru: 'GMT+10:00, Восточная Австралия, Гуам, Владивосток',
	},
	{
		id: '+10:30',
		gmt: 'GMT+10:30',
		title_en: 'GMT+10:30, Lord Howe Island',
		title_ru: 'GMT+10:30, Остров Лорд-Хау',
	},
	{
		id: '+11:00',
		gmt: 'GMT+11:00',
		title_en: 'GMT+11:00, Magadan, Solomon Islands, New Caledonia',
		title_ru: 'GMT+11:00, Магадан, Соломоновы острова, Новая Каледония',
	},
	{
		id: '+11:30',
		gmt: 'GMT+11:30',
		title_en: 'GMT+11:30, Norfolk Island',
		title_ru: 'GMT+11:30, Остров Норфолк',
	},
	{
		id: '+12:00',
		gmt: 'GMT+12:00',
		title_en: 'GMT+12:00, Auckland, Wellington, Fiji, Kamchatka',
		title_ru: 'GMT+12:00, Окленд, Веллингтон, Фиджи, Камчатка',
	},
	{
		id: '+12:45',
		gmt: 'GMT+12:45',
		title_en: 'GMT+12:45, Chatham Islands',
		title_ru: 'GMT+12:45, Острова Чатем',
	},
	{
		id: '+13:00',
		gmt: 'GMT+13:00',
		title_en: 'GMT+13:00, Apia, Nukualofa',
		title_ru: 'GMT+13:00, Апиа, Нукуалофа',
	},
	{
		id: '+14:00',
		gmt: 'GMT+14:00',
		title_en: 'GMT+14:00, Line Islands, Tokelau',
		title_ru: 'GMT+14:00, Острова Лайн, Токелау',
	},
];
